import React, { useContext, useState } from "react";
import authContext from "../layout/GetUserStatus";
import "../assets/new/css/bootstrap.css";
import "../assets/new/css/style.css";
import "../assets/new/css/color-1.css";
import "../assets/new/css/responsive.css";
import "../assets/new/css/fontawesome.css";
import "../assets/new/css/date-picker.css";
//import $ from 'jquery';
import { useTranslation } from "react-i18next";

import logoImg from "../assets/new/images/logo/logo.png";
import {
  Maximize,
  ShoppingCart,
  Bell,
  Grid,
  Activity,
  CheckCircle,
  FileText,
  UserCheck,
  MoreHorizontal,
} from "react-feather";
import logoDarkImg from "../assets/new/images/logo/dark-logo.png";
import profileAvatar from "../assets/new/images/profile/avatar.png";
import ApiConfig from "../api/ApiConfig";
//import { Button } from 'react-bootstrap';
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import ChangeLang from "../component/languageChange/ChangeLang";
import { simplePostCall } from "../api/ApiServices";
import Params from "../config/Params";
import { useEffect } from "react";

const Header = () => {
  const [language, setLanguage] = useState("");
  const [profileDataState, setProfileDataState] = useState({});
  console.log("profileDataState", profileDataState);
  const [state, setState] = useState(true);
  const [darkMode, setDarkMode] = useState(true);
  const { setAuthenticated, UserProfile } = useContext(authContext);
  const [navMenuOpen, setNavMenuOpen] = useState(true);
  const { i18n, t } = useTranslation();
  const [dark, setDark] = useState(true);

  const handleLangChange = (evt) => {
    const lang = evt.target.value;
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  function handleClick() {
    if (state === true) {
      setState(false);
      document.getElementById("myID").classList.add("close_icon");
      document.getElementById("container").style.marginLeft = "0px";
      document.getElementById("footer").style.marginLeft = "0px";
    } else {
      setState(true);
      document.getElementById("myID").classList.remove("close_icon");
      document.getElementById("container").style.marginLeft = "290px";
      document.getElementById("footer").style.marginLeft = "275px";
    }
  }

  useEffect(() => {
    if (profileDataState === "dark") {
      if (profileDataState === "dark") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.remove("dark-only");
      }
    } else {
      document.body.classList.remove("dark-only");
    }
  }, [profileDataState]);

  function DarkMode() {
    if (dark) {
      handleTheme("dark");
    } else {
      handleTheme("light");
    }
  }

  function handleNavMenuOpen() {
    if (navMenuOpen === true) {
      document.getElementById("navMenus").classList.add("open");
    } else {
      document.getElementById("navMenus").classList.remove("open");
    }
  }
  useEffect(() => {
    getUserProfileData();
  }, []);

  function getUserProfileData() {
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      student_id: Params.student_id,
    });

    simplePostCall(ApiConfig.USERT_PROFILE, newRequestBody)
      .then((data) => {
        if (data.result) {
          setLanguage(data.data[0].student_language);
          setProfileDataState(data.data[0].student_theme);
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const handleTheme = async (Status) => {
    console.log(Status);

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      theme: Status,
    });

    simplePostCall(ApiConfig.STUDENT_THEME, newRequestBody)
      .then((data) => {
        if (data.result) {
          getUserProfileData();
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleLanguage = async (lang) => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      language: lang,
    });

    simplePostCall(ApiConfig.STUDENT_LANGUAGE, newRequestBody)
      .then((data) => {
        if (data.result) {
          getUserProfileData();
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-main-header" id="page-main-header">
          <div className="main-header-right row m-0">
            <div className="main-header-left">
              <div className="toggle-sidebar" onClick={handleClick}>
                <i className="status_toggle middle" id="sidebar-toggle">
                  <Grid />
                </i>
              </div>
              <div className="logo-wrapper">
                <a href="/student/dashboard">
                  <img className="img-fluid" src={logoImg} alt="" />
                </a>
              </div>
              <div className="dark-logo-wrapper">
                <a href="/student/dashboard">
                  <img className="img-fluid" src={logoDarkImg} alt="" />
                </a>
              </div>
            </div>
            <div className="left-menu-header col">
              <ul>
                <li>
                  <form className="form-inline search-form">
                    <div className="search-bg">
                      <i className="fa fa-search"></i>
                      <input
                        className="form-control-plaintext"
                        placeholder="Search Courses"
                      />
                    </div>
                  </form>
                  <span className="d-sm-none mobile-search search-bg">
                    <i className="fa fa-search"></i>
                  </span>
                </li>
              </ul>
            </div>
            <div className="nav-right col pull-right right-menu p-0">
              <ul id="navMenus" className="nav-menus">
                <li
                  onClick={() => {
                    document.body.requestFullscreen();
                  }}
                >
                  <a className="text-dark" href="#!">
                    <Maximize />
                  </a>
                </li>
                <li
                  onClick={() => {
                    setDark(!dark);
                    DarkMode();
                  }}
                >
                  <div className="mode">
                    <i
                      className={
                        darkMode ? "fa fa-moon-o" : "fa fa-lightbulb-o"
                      }
                    ></i>
                  </div>
                </li>
                <li className="onhover-dropdown">
                  <div className="bookmark-box">
                    <Link to="/my-cart" style={{ color: "#2b2b2b" }}>
                      <ShoppingCart />
                    </Link>
                    <span className="dot-animated">4</span>
                  </div>
                </li>
                <li className="onhover-dropdown">
                  <div className="bookmark-box">
                    {/* <ChangeLang/> */}

                    <select
                      className="lang_select"
                      onChange={handleLangChange}
                      value={language}
                    >
                      <option value="en">English </option>
                      <option value="ar">Arabic </option>
                      <option value="ta">Tamil</option>
                    </select>
                  </div>
                </li>
                <li className="onhover-dropdown">
                  <div className="notification-box">
                    <Bell />
                    <span className="dot-animated">4</span>
                  </div>
                  <ul className="notification-dropdown onhover-show-div">
                    <li>
                      <p className="f-w-700 mb-0">
                        You have 4 Notifications
                        <span className="pull-right badge badge-primary badge-pill">
                          4
                        </span>
                      </p>
                    </li>
                    <li className="noti-primary">
                      <div className="media">
                        <span className="notification-bg bg-light-primary">
                          <i>
                            <Activity />
                          </i>
                        </span>
                        <div className="media-body">
                          <p>Delivery processing </p>
                          <span>10 minutes ago</span>
                        </div>
                      </div>
                    </li>
                    <li className="noti-secondary">
                      <div className="media">
                        <span className="notification-bg bg-light-secondary">
                          <CheckCircle />
                        </span>
                        <div className="media-body">
                          <p>Order Complete</p>
                          <span>1 hour ago</span>
                        </div>
                      </div>
                    </li>
                    <li className="noti-success">
                      <div className="media">
                        <span className="notification-bg bg-light-success">
                          <FileText />
                        </span>
                        <div className="media-body">
                          <p>Tickets Generated</p>
                          <span>3 hour ago</span>
                        </div>
                      </div>
                    </li>
                    <li className="noti-danger">
                      <div className="media">
                        <span className="notification-bg bg-light-danger">
                          <UserCheck />
                        </span>
                        <div className="media-body">
                          <p>Delivery Complete</p>
                          <span>6 hour ago</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="onhover-dropdown">
                  <div className="bookmark-box">
                    <img
                      className="onhover-avtr"
                      src={
                        UserProfile &&
                        ApiConfig.BASE_URL + UserProfile.student_profilepicture
                          ? ApiConfig.BASE_URL +
                            UserProfile.student_profilepicture
                          : profileAvatar
                      }
                    />
                  </div>
                  <div className="bookmark-dropdown onhover-show-div">
                    <ul className="m-t-5">
                      {/* <li className="add-to-bookmark">
                        <Link to="/my-courses">
                          <i
                            className="icofont icofont-hat-alt"
                            style={{ fontSize: 20, marginRight: 15 }}
                          ></i>{" "}
                          {t('My Courses')}
                        </Link>
                      </li>
                      <li className="add-to-bookmark">
                        <Link to="">
                          <i
                            className="icofont icofont-ui-love"
                            style={{ fontSize: 20, marginRight: 15 }}
                          ></i>{" "}
                          {t('My Favourites')}
                        </Link>
                      </li> */}
                      {/* <li className="add-to-bookmark">
                        <Link to="/calendar">
                          <i
                            className="icofont icofont-ui-calendar"
                            style={{ fontSize: 20, marginRight: 15 }}
                          ></i>{" "}
                          {t('My Schedule')}
                        </Link>
                      </li> */}
                      <li className="add-to-bookmark">
                        <Link to="/attendance">
                          <i
                            className="icofont icofont-tick-boxed"
                            style={{ fontSize: 20, marginRight: 15 }}
                          ></i>{" "}
                          {t("My Attendance")}
                        </Link>
                      </li>
                      <li className="add-to-bookmark">
                        <Link to="my-wallet">
                          <i
                            className="icofont icofont-wallet"
                            style={{ fontSize: 20, marginRight: 15 }}
                          ></i>{" "}
                          {t("My Wallet")}
                        </Link>
                      </li>
                      <li className="add-to-bookmark">
                        <Link
                          to=" "
                          onClick={() => {
                            setAuthenticated(false);
                            localStorage.setItem(
                              "is_logged_in",
                              JSON.stringify(false)
                            );
                            if (localStorage.getItem("loggedin_url")) {
                              var loggedin_url =
                                localStorage.getItem("loggedin_url");
                              localStorage.removeItem("loggedin_url");
                              window.location.replace(
                                loggedin_url + "/login/logout"
                              );
                            }
                          }}
                        >
                          <i
                            className="icofont icofont-exit"
                            style={{ fontSize: 20, marginRight: 10 }}
                          ></i>{" "}
                          {t("Logout")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="d-lg-none mobile-toggle pull-right w-auto">
              <MoreHorizontal
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNavMenuOpen(!navMenuOpen);
                  handleNavMenuOpen();
                }}
              />
            </div>
          </div>
        </div>
        <Sidebar />
      </div>
    </>
  );
};
export default Header;
