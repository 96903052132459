import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer";

import "../../assets/new/css/MyDiary.css";
import "../../assets/new/css/SchoolTvChannel.css";
import "../../assets/new/css/library.css";
import "../../assets/new/css/wallet.css";
import { withTranslation } from "react-i18next";

const CashlessWallet = () => {
  const listData = [
    {
      id: 1,
      date: "12-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: " $300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 2,
      date: "12-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$80",
      simple: "-",
      color: "red",
    },
    {
      id: 3,
      date: "11-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$100",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 4,
      date: "09-Aug-2023",
      upi: "UPI/3453453454/UPI",
      color: "red",
      amt: "$200",
      simple: "-",
      color: "red",
    },
    {
      id: 5,
      date: "09-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 6,
      date: "08-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$200",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 7,
      date: "08-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$450",
      simple: "-",
      color: "red",
    },
    {
      id: 8,
      date: "07-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$50",
      simple: "+",
      color: "#72A44B",
    },
  ];
  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <div class="library">
              <div>
                <div className="row">
                  <div>
                    <div className="col-md-6">
                      <label className="Balance">Balance</label>
                    </div>
                    <div className="col-md-6">
                      <label className="walletcash">200.00</label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="books-main-wrapper">
                      <TabView>
                        <TabPanel
                          header="Last 10 Transactions"
                          className="tabheadstyle"
                          headerStyle={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="row">
                            {listData.map((item, index) => (
                              <div className="col-md-4" key={index}>
                                <Link to="/TransactionHis">
                                  <div className="d-flex justify-content-between flex-row p-2 my-1 rounded-3 shadow-sm walletcard">
                                    <div>
                                      <p className="walletdatetxt">
                                        {item.date}
                                      </p>
                                      <p
                                        style={{
                                          color: "#857E84",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.upi}
                                      </p>
                                    </div>

                                    <div
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: item.color,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {item.simple}
                                      </p>
                                      <p className="walletamout">{item.amt}</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </TabPanel>

                        <TabPanel
                          header="Detailed Transactions"
                          className="tabheadstyle"
                          headerStyle={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <p>For which period do you need a statement?</p>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                  />
                                  <label
                                    class="form-check-label lastmonth"
                                    for="exampleRadios1"
                                  >
                                    Last Month
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="option2"
                                  />
                                  <label
                                    class="form-check-label  lastmonth"
                                    for="exampleRadios2"
                                  >
                                    Last 6 months
                                  </label>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios3"
                                    value="option3"
                                  />
                                  <label
                                    class="form-check-label   lastmonth"
                                    for="exampleRadios3  lastmonth"
                                  >
                                    Last 3 Months
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios4"
                                    value="option4"
                                  />
                                  <label
                                    class="form-check-label  lastmonth"
                                    for="exampleRadios4"
                                  >
                                    Last Year
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <p>Or select a custom date of your choice</p>
                            <div className="row">
                              <div className="form-group col-md-6  lastmonth">
                                <label for="task-title">From</label>
                                <input
                                  className="datepicker-here form-control digits"
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                />
                              </div>
                              <div className="form-group col-md-6  lastmonth">
                                <label for="task-title">To</label>
                                <input
                                  className="datepicker-here form-control digits"
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 btnshpro">PROCEED</div>
                        </TabPanel>
                      </TabView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CashlessWallet;
